import axios from "axios";
import qs from "qs";
// import { getCookie } from "tiny-cookie";
// import SmartStorage from "../storage/index.js";
import Toast from "vant/lib/toast";
import "vant/lib/toast/style";
import Dialog from "vant/lib/dialog";
import "vant/lib/dialog/style";

class SmartHttp {
  constructor(options) {
    // 401处理
    this.handle401 = options.handle401 || null;
    // 是否需要加载动画
    this.disableLoadingAnimation = options.disableLoadingAnimation || false;
    // 请求动画
    this.oLoadingAnimation = null;
    // 请求数量
    this.nNumberOfRequests = 0;
    // 请求动画黑名单
    this.aLoadingBlackList = options.aLoadingBlackList || [];
    // 请求原型
    this.request = axios.create({
      baseURL: options.baseURL,
      timeout: options.timeout || 10000, // 请求超时时间
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      }
    });

    this.injectRequest();
    this.injectResponse();
  }

  // 请求拦截
  injectRequest() {
    this.request.interceptors.request.use(
      config => {
        // const token = getCookie("token") || SmartStorage.get("token");
        // if (token) {
        //   config.headers["Authorization"] = "Bearer " + token;
        // }


        // get请求添加时间搓
        if (config.method === "get") {
          config.params = Object.assign(config.params || {}, {
            rnd: new Date().getTime()
          });
        }

        if (
          config.data &&
          config.headers["Content-Type"] === "application/x-www-form-urlencoded"
        ) {
          config.data = qs.stringify(config.data);
        }

        //开始动画
        if (
          !this.disableLoadingAnimation &&
          this.aLoadingBlackList.indexOf(config.url) === -1
        ) {
          this.sLoadingAnimation();
        }

        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  // 响应拦截
  injectResponse() {
    this.request.interceptors.response.use(
      response => {
        const {
          data,
          status,
          config: { url }
        } = response;

        // 结束动画
        if (
          !this.disableLoadingAnimation &&
          this.aLoadingBlackList.indexOf(url) === -1
        ) {
          this.eLoadingAnimation();
        }

        // 根据 status 状态拦截
        switch (status) {
          case 200:
            // Todo: 业务状态为false时做处理
            // 之所以会加!Number(data.code)的判断，是因为code有值的情况，前端需要做对应的处理，有待调整！！！！
            // 后端大部分情况都是0，但是0不做特殊处理，弹出提示即可
            if (data.success === false && (data.code==0||!Number(data.code))) {
              let message = "";
              if (data.content && typeof data.content !== "string" && data.content.length > 0) {
                data.content.forEach((item, index) => {
                  message += index + 1 + ". " + item.msg + "\n";
                });
              }

              Dialog({
                title: "提示",
                message:
                  message ||
                  data.msg ||
                  "系统异常,请稍候再试\n如持续报错，请致电客服获得帮助"   // "网络暂时异常,请稍候再试\n如持续报错，请致电客服获得帮助"
              });
            }
            return data;
          default:
            return Promise.reject(response);
        }
      },
      error => {
        const url = error.config.url;
        // 结束动画
        if (
          !this.disableLoadingAnimation &&
          this.aLoadingBlackList.indexOf(url) === -1
        ) {
          this.eLoadingAnimation();
        }

        if (error.response) {
          const { data, status } = error.response;

          // 根据 status 状态拦截
          switch (status) {
            case 400:
              return data;
            case 401:
              Dialog({
                title: "提示",
                message:
                  data.msg ||
                  "当前权限异常,请尝试重新登录\n如持续报错，请致电客服获得帮助"
              }).then(() => {
                console.warn("Dialog close");
                if (this.handle401) {
                  this.handle401();
                  return;
                }
              });
              return data;
            case 403:
              this.isBlockErrorRequest = true;
              Dialog({
                title: "提示",
                message: "拒绝请求，请稍后重试（403）"
              });
              return data;
            case 500:
              this.isBlockErrorRequest = true;
              Dialog({
                title: "提示",
                message: "服务器错误，请稍后重试（500）"
              });
              return data;
            default:
              // Dialog({
              //   title: "提示",
              //   message: data.msg || "服务器异常，请稍后重试"
              // });
              console.log(data.msg)
              return data;
          }
        } else {
          Dialog({
            title: "提示",
            message: "网络连接超时,请稍候再试\n如持续报错，请致电客服获得帮助"
          });
          return {};
        }
      }
    );
  }

  /**
   * 开始动画
   */
  sLoadingAnimation() {
    // 如果请求数量等于 0，则开始动画
    if (this.nNumberOfRequests === 0) {
      this.oLoadingAnimation = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "加载中..."
      });
    }
    // 请求数量++
    this.nNumberOfRequests++;
  }

  /**
   * 结束动画
   */
  eLoadingAnimation() {
    // 请求数量--
    this.nNumberOfRequests--;
    // 如果请求数量小于等于 0，则结束动画
    if (this.nNumberOfRequests <= 0) {
      this.oLoadingAnimation.clear();
    }
  }
}

export default SmartHttp;
