<template>
  <div id="app" class="app" ref="wrapper" :style="{ minHeight: wrapperHeight + 'px' }">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
      wrapperHeight: 0,
    }
  },
  components: {
  },
  mounted() {
    document.title = '学习党的二十大精神-网络知识竞赛';
    this.wrapperHeight =
        (document.documentElement.clientHeight || window.innerHeight) -
        this.$refs.wrapper.getBoundingClientRect().top;
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.14rem;
}

html {
  background: #fff;
}
</style>
