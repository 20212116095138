import Vue from 'vue';
import Router from 'vue-router';
import {SmartStorage} from '../core'
Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/index',
        },
        {
            path: '/index', //首页
            name: 'index',
            component: resolve => require(["@/view/index.vue"], resolve),
        },
        {
            path: '/mainIndex', //首页
            name: 'mainIndex',
            component: resolve => require(["@/view/mainIndex.vue"], resolve),
        },
        {
            path: '/login', //登录
            name: 'login',
            component: resolve => require(["@/view/login.vue"], resolve),
        },
        {
            path: '/answer', //答题
            name: 'answer',
            component: resolve => require(["@/view/answer.vue"], resolve),
        },
        {
            path: '/result', //结果
            name: 'result',
            component: resolve => require(["@/view/result.vue"], resolve),
        },
        {
            path: '/analysis', //答案解析
            name: 'analysis',
            component: resolve => require(["@/view/analysis.vue"], resolve),
        },
        {
            path: '/ranking', //查看排名
            name: 'ranking',
            component: resolve => require(["@/view/ranking.vue"], resolve),
        },
    ]
})

router.beforeEach((to, from, next) => {
    let beginData = SmartStorage.get('begin')
    if (!['/', '/index'].includes(to.path) && !beginData) {
        next({path: `/index`});
    } else {
        next();
    }
});

export default router;