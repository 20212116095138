
 /* eslint-disable */
class SmartStorage {
    constructor() {

    }

    static oldset(key, value) {
        if (value === undefined || value === null) {
            return;
        }
        sessionStorage.removeItem(key);
        var val = value;
        if (["string", "number", "boolean"].indexOf(typeof (value)) < 0) {
            val = JSON.stringify(value);
        }
        sessionStorage.setItem(key, val);
    }
    /**
     * 
     * @param {*} key 
     * 
     */
    static oldget(key) {
        var val = sessionStorage.getItem(key);
        if (val === null) {
            return null;
        }
        if (val == "true" || val == "false") {
            return val == "true";
        }
        if (typeof val == 'string') {
            try {
                var obj=JSON.parse(val);
                if(typeof obj == 'object' && obj ){
                    return obj;
                }else{
                    return val;
                }
    
            } catch(e) {
                return val;
            }
        }
        return val;
    }


    static get(k) {
        let origin = sessionStorage.getItem(k)
        if (origin === null || origin === undefined) {
            return null
        }
        let str = origin
        str = str.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, '@');
        str = str.replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']');
        str = str.replace(/(?:^|:|,)(?:\s*\[)+/g, '');
        if ((/^[\],:{}\s]*$/).test(str)) {
            return JSON.parse(origin)
        } else {
            return origin
        }
    }
    static set(k, v) {
        sessionStorage.setItem(k, JSON.stringify(v))
    }

    static getAll() {
        var sessionData = {};
        Object.keys(sessionStorage).forEach((key) => {
            sessionData[key] = this.get(key);
        })
        return sessionData;
    }

    static remove(key) {
        sessionStorage.removeItem(key);
    }
    static clear() {
        sessionStorage.clear();
    }

    /**
     * 
     * @param {*} obj 
     *  判断对象是否是字符串
     */
    static isString(obj) {
        return Object.prototype.toString.call(obj) === "[object String]";
    }


}

export default SmartStorage;