import Vue from 'vue'
import App from './App.vue'
import router from './router';
import {SmartStorage} from './core'
import {httpService} from "@/api/api";
// 引入rem.js
import './assets/js/rem'
//公共css样式
import '@/assets/css/public.css';
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
Vue.config.productionTip = false
Vue.prototype.iStorage = SmartStorage;
Vue.prototype.$service = httpService
new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
